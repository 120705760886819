import React, { createRef } from 'react';
import Layout from '../components/layout/layout';
import Navigation from '../components/navigation/navigation';
import About from '../components/about/about';
import Footer from '../components/footer/footer';

const JamesEnPage = () => {
  const {james} = createRef;
  const engArray = [
    {
      key: 2,
      content: `James was born in the United Kingdom and moved to Helsinki from the United Kingdom 7 years ago to discover more about his finnish roots as he is half finnish and half british. He speaks well in both Finish and English, however English is a stronger language. He enjoys the space and calmness of Finnish culture and the proximity to forest and nature.`,
    },
    {
      key: 3,
      content: `James has always enjoyed working in people oriented roles and in settings where he can provide a service to people that relieves pain and dysfunction and where he benefits other people's quality of life. He loves to build therapeutic relationships with customers and enjoys hearing about what's important to each person and to provide the most individualized treatment possible, he believes that any physical problems also relate to mental well being and interact with social well being and work capacity, that any treatments given should be done so with  a holistic approach.`,
    },
    {
      key: 4,
      content: `James has experience of working in the healthcare field of work, as an assistant nurse in clinical settings, learning disabilities and in mental health practice in the UK and Finland, he has studied in the field of occupational therapy and rehabilitation, he holds qualifications in the areas of practical nursing, healthcare practitioner and as a qualified massage therapist.`,
    },
    {
      key: 5,
      content: `He loves to learn about how the body works and human anatomy and has enjoyed applying these interests to learn the skills of massage therapy he also has a special interest in mental and social health and the interaction of these things and is always looking for ways to develop his knowledge and skills in all of these areas with further study and training.`,
    },
    {
      key: 6,
      content: `James qualified from Helsinki Hierojakoulu in 2022 and established his own massage therapy company.`,
    },
    {
      key: 7,
      content: `In his free time he enjoys playing badminton 3 times a week at a local club, road cycling, climbing, hanging out with friends and long hikes in the forest. He also has a weird interest in fantasy and sci fi genre of films and series. James is looking forward to putting his skills knowledge and experience into practice in helping customers to find freedom from pain, 
      discomfort or stress that might be holding them back and helping to promote positive physical and mental health for customers.`,
    }
  ];

  return (
      <Layout>
        <title>James</title>
        <div className='site-wrapper'>
          <Navigation
            homeRoute={`/en`}
            langLink={`FI`}
            langTitle={'Englanninkielinen sivu'}
            language={`/james`}
            home={`Home`}
          />
          <main className="new-page">
            <About 
              sectionTitle={`James`} 
              ref={james}
              aboutTextArray={engArray}
            />
          </main>
          <Footer followMe={`Follow Us`} contactInfo={`Contact`}/>
        </div>
      </Layout>
  )
}

export default JamesEnPage;